<script>
import i18n from '@src/i18n'
const Layout = () => import('@layouts/settings/settings')
const CompanySettings = () =>
  import('@components/company-settings/company-settings')

export default {
  page: {
    title: i18n.t('COMPANY_SETTINGS'),
    meta: [{ name: 'description', content: '' }],
  },
  components: { Layout, CompanySettings },
}
</script>

<template>
  <Layout>
    <CompanySettings />
  </Layout>
</template>
